'use client';

import * as React from 'react';

import { cn } from '@ui/lib/utils';

import { useSidebar } from '@/features/portal-layout/hooks/use-sidebar';

export interface SidebarProps extends React.ComponentProps<'div'> {}

export function Sidebar({ className, children }: SidebarProps) {
	const { isSidebarOpen, isLoading } = useSidebar();

	return (
		<div
			data-state={isSidebarOpen && !isLoading ? 'open' : 'closed'}
			className={cn(className, 'bg-background h-full flex-col')}>
			{children}
		</div>
	);
}

'use client';

import { AnimatePresence, motion } from 'framer-motion';

import { slugify } from '@tools/utils/url/url';

import { SidebarItem } from '@/features/portal-layout/components/sidebar-item';
import { MenuItem } from '@/lib/types';

interface SidebarItemsProps {
	items?: MenuItem[];
}

export function SidebarItems({ items }: SidebarItemsProps) {
	if (!items?.length) return null;

	return (
		<AnimatePresence>
			{items.map(
				(item, index) =>
					item && (
						<motion.div
							key={`sidebar-menu-item-${slugify(item.title)}`}
							exit={{
								opacity: 0,
								height: 0,
							}}>
							<SidebarItem index={index} item={item} />
						</motion.div>
					)
			)}
		</AnimatePresence>
	);
}

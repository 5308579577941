import { isDevelopment, isPreview } from '../environment/environment';

export const slugify = (str: string) =>
	str
		.toLowerCase()
		.trim()
		.replace(/[^\w\s-]/g, '')
		.replace(/[\s_-]+/g, '-')
		.replace(/^-+|-+$/g, '');

export function getBaseUrl(prodHostname: string): string {
	if (isDevelopment) {
		return 'http://localhost:3000';
	}
	if (isPreview) {
		return `https://${process.env.VERCEL_URL}`;
	}
	return `https://${prodHostname}`;
}

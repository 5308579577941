import React from 'react';

import ThemeController from '@ui/components/layout/theme-controller/theme-controller';

import { SidebarItems } from '@/features/portal-layout/components/sidebar-items';
import ButtonSupport from '@/features/support/components/button-support';
import { MenuItem } from '@/lib/types';

const menuItems: MenuItem[] = [
	{
		title: 'Diepte-interview',
		href: '/portal/interview',
	},
	{
		title: 'Chat',
		href: '/portal/chat',
	},
	{
		title: 'Delphi kalender',
		href: '/portal/delphi-calendar',
	},
];

export async function SidebarList() {
	return (
		<div className='flex h-full flex-col py-16 lg:py-8'>
			<div className='flex flex-1 flex-col overflow-hidden'>
				<div className='flex-1 overflow-auto'>
					<div className='space-y-2 pr-2'>
						<SidebarItems items={menuItems} />
					</div>
				</div>
			</div>
			<div className='flex flex-row justify-evenly gap-2 px-4'>
				<ThemeController />
				<ButtonSupport />
			</div>
		</div>
	);
}

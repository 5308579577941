'use client';

import { motion } from 'framer-motion';
import { Link } from 'next-view-transitions';
import { usePathname } from 'next/navigation';
import { PropsWithChildren } from 'react';
import { useLocalStorage } from 'react-use';

import { buttonVariants } from '@ui/components/ui/button';
import { cn } from '@ui/lib/utils';

import { type MenuItem } from '@/lib/types';

type SidebarItemProps = PropsWithChildren<{
	index: number;
	item: MenuItem;
}>;

export function SidebarItem({ index, item }: SidebarItemProps) {
	const pathname = usePathname();
	const isActive = pathname.includes(item.href);
	const [menuItemId, setMenuItemId] = useLocalStorage('menuItemId', null);
	const shouldAnimate = index === 0 && isActive && menuItemId;

	return (
		<motion.div
			className='relative h-full'
			variants={{
				initial: {
					height: 0,
					opacity: 0,
				},
				animate: {
					height: 'auto',
					opacity: 1,
				},
			}}
			initial={shouldAnimate ? 'initial' : undefined}
			animate={shouldAnimate ? 'animate' : undefined}
			transition={{
				duration: 0.25,
				ease: 'easeIn',
			}}>
			<Link
				href={item.href}
				title={item.title}
				className={cn(
					buttonVariants({ variant: 'ghost' }),
					'group w-full hover:bg-zinc-200/40 dark:hover:bg-zinc-300/10',
					isActive && 'bg-zinc-200 font-semibold dark:bg-zinc-800'
				)}>
				<div className='relative ml-2 max-h-5 flex-1 select-none overflow-hidden text-ellipsis break-all text-left'>
					<span className='whitespace-nowrap'>
						{shouldAnimate ? (
							item.title.split('').map((character, index) => (
								<motion.span
									key={index}
									variants={{
										initial: {
											opacity: 0,
											x: -100,
										},
										animate: {
											opacity: 1,
											x: 0,
										},
									}}
									initial={shouldAnimate ? 'initial' : undefined}
									animate={shouldAnimate ? 'animate' : undefined}
									transition={{
										duration: 0.25,
										ease: 'easeIn',
										delay: index * 0.05,
										staggerChildren: 0.05,
									}}
									onAnimationComplete={() => {
										if (index === item.title.length - 1) {
											setMenuItemId(null);
										}
									}}>
									{character}
								</motion.span>
							))
						) : (
							<span>{item.title}</span>
						)}
					</span>
				</div>
			</Link>
		</motion.div>
	);
}

'use client';

import { Link } from 'next-view-transitions';

import { Button } from '@ui/components/ui/button';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@ui/components/ui/dropdown-menu';

import { signOut } from '@/features/auth/actions';
import { type Session } from '@/lib/types';

export interface UserMenuProps {
	user: Session['user'];
	isInteractive: boolean;
}

function getUserInitials(name: string) {
	const [firstName, lastName] = name.split(' ');
	// @ts-ignore - name always contains a space
	return lastName ? `${firstName[0]}${lastName[0]}` : firstName.slice(0, 2);
}

export function UserMenu({ user, isInteractive }: UserMenuProps) {
	return (
		<div className='ml-2 flex items-center justify-between gap-4'>
			<DropdownMenu>
				<DropdownMenuTrigger asChild>
					<Button variant='ghost'>
						<div className='bg-muted/50 text-muted-foreground flex size-7 shrink-0 select-none items-center justify-center rounded-full text-xs font-medium uppercase'>
							{getUserInitials(user.email)}
						</div>
						<span className='ml-2 hidden md:block'>{user.email}</span>
					</Button>
				</DropdownMenuTrigger>

				<DropdownMenuContent sideOffset={8} align='start' className='w-fit'>
					{isInteractive && (
						<>
							<DropdownMenuItem className='flex-col items-start'>
								<Link href={`/portal/profile`} title={`Ga naar profiel van ${user.email}`}>
									Profiel
								</Link>
							</DropdownMenuItem>
							<DropdownMenuSeparator />
						</>
					)}

					<form action={signOut}>
						<button
							type='submit'
							className='focus:bg-accent focus:text-accent-foreground relative flex w-full cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors hover:bg-red-500 hover:text-white data-[disabled]:pointer-events-none data-[disabled]:opacity-50'>
							Uitloggen
						</button>
					</form>
				</DropdownMenuContent>
			</DropdownMenu>
		</div>
	);
}

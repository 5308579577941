'use client';

import * as React from 'react';

import { Menu } from '@ui/components/icons/icons';
import { Button } from '@ui/components/ui/button';

import { useSidebar } from '@/features/portal-layout/hooks/use-sidebar';

export function SidebarToggle() {
	const { toggleSidebar } = useSidebar();

	return (
		<Button
			variant='ghost'
			className='-ml-2 hidden size-9 p-0 lg:flex'
			onClick={() => {
				toggleSidebar();
			}}>
			<Menu className='size-6' />
			<span className='sr-only'>Toggle Sidebar</span>
		</Button>
	);
}

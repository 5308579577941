'use client';

import { Link } from 'next-view-transitions';
import { usePathname } from 'next/navigation';

import ThemeController from '@ui/components/layout/theme-controller/theme-controller';
import { Button, buttonVariants } from '@ui/components/ui/button';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@ui/components/ui/dropdown-menu';
import { cn } from '@ui/lib/utils';

import { Logo } from '@/components/logo';
import config from '@/config';
import { UserMenu } from '@/features/auth/components/user-menu';
import { SidebarMenu } from '@/features/portal-layout/components/sidebar-menu';
import { SidebarMobile } from '@/features/portal-layout/components/sidebar-mobile';
import { SidebarToggle } from '@/features/portal-layout/components/sidebar-toggle';
import { Session, User } from '@/lib/types';

const publicLinks = [
	{ href: '#features', label: 'Oplossing' },
	{ href: '#team', label: 'Team' },
	{ href: '#faq', label: 'FAQ' },
];

function UserOrLogin({ session }: { session?: Session }) {
	const pathname = usePathname();
	const isOnSubscription = !!(pathname && pathname.includes('/subscription'));

	return (
		<>
			<div className='flex items-center'>
				{isOnSubscription && <ThemeController className='btn btn-ghost max-sm:hidden' />}
				{session?.user && <UserMenu user={session.user as User} isInteractive={!isOnSubscription} />}
			</div>
		</>
	);
}

function PublicLinks() {
	return (
		<>
			<div className='lg:hidden'>
				<DropdownMenu>
					<DropdownMenuTrigger className='btn btn-ghost'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							className='h-5 w-5'
							fill='none'
							viewBox='0 0 24 24'
							stroke='currentColor'>
							<path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M4 6h16M4 12h8m-8 6h16' />
						</svg>
					</DropdownMenuTrigger>
					<DropdownMenuContent>
						{publicLinks.map(({ href, label }) => (
							<DropdownMenuLabel key={href}>
								<Link href={href} className={cn(buttonVariants({ variant: 'ghost' }), 'w-full')}>
									{label}
								</Link>
							</DropdownMenuLabel>
						))}
						<DropdownMenuGroup className='sm:hidden'>
							<DropdownMenuSeparator />
							<DropdownMenuItem>
								<ThemeController className='w-full' />
							</DropdownMenuItem>
						</DropdownMenuGroup>
					</DropdownMenuContent>
				</DropdownMenu>
			</div>
			<ul className='menu menu-horizontal hidden px-1 lg:flex'>
				{publicLinks.map(({ href, label }) => (
					<li key={href}>
						<Link href={href} className={cn(buttonVariants({ variant: 'ghost' }))}>
							{label}
						</Link>
					</li>
				))}
			</ul>
		</>
	);
}

type Props = {
	variant: 'landing' | 'public' | 'portal' | 'subscription';
	session?: Session;
};

export default function Header({ variant, session }: Props) {
	// const isAuthenticated = !!session?.user;
	const isAuthenticated = false;

	return (
		<header
			className={cn(
				variant === 'portal' ? 'px-4-safe' : 'px-0-safe',
				'bg-background dark:border-border sticky top-0 z-50 h-16 shadow-sm lg:h-20 dark:border-b dark:shadow-none'
			)}>
			<nav className={cn(['landing', 'public', 'subscription'].includes(variant) && 'container', 'navbar h-full')}>
				<div className='navbar-start'>
					{variant === 'portal' && (
						<>
							<SidebarMobile>
								<SidebarMenu />
							</SidebarMobile>
							<SidebarToggle />
						</>
					)}
					{['public', 'landing'].includes(variant) && (
						<>
							{variant === 'landing' && (
								<div className='lg:hidden'>
									<PublicLinks />
								</div>
							)}

							<Link
								className='btn btn-ghost text-foreground flex shrink-0 items-center gap-2'
								href='/'
								title={`${config.appName} homepage`}>
								<Logo className='max-sm:text-2xl' />
							</Link>
						</>
					)}
				</div>
				<div className='navbar-center lg:flex'>
					{variant === 'portal' && (
						<Link
							className='btn btn-ghost text-foreground flex shrink-0 items-center gap-2'
							href='/portal'
							title={`${config.appName} homepage`}>
							<Logo className='max-sm:text-2xl' />
						</Link>
					)}
					{['landing'].includes(variant) && (
						<>
							<div className='max-lg:hidden'>
								<PublicLinks />
							</div>
						</>
					)}
					{variant === 'subscription' && (
						<Link
							className='btn btn-ghost text-foreground flex shrink-0 items-center gap-2'
							href='/'
							title={`${config.appName} homepage`}>
							<Logo className='max-sm:text-2xl' />
						</Link>
					)}
				</div>
				<div className='navbar-end gap-2'>
					{['portal', 'subscription'].includes(variant) && (
						<>
							<UserOrLogin session={session} />
						</>
					)}

					<div className='flex items-center gap-2'>
						{['landing', 'public'].includes(variant) && (
							<>
								<ThemeController className='btn btn-ghost max-sm:hidden' />
								{isAuthenticated ? (
									<Link href={'/portal'} className={buttonVariants({ variant: 'default' })}>
										Ga naar Delphi
									</Link>
								) : (
									<>
										{variant === 'landing' && (
											<>
												<Button className='max-sm:hidden' size='lg' asChild>
													<Link href='#signup'>Inschrijven</Link>
												</Button>
											</>
										)}
									</>
								)}
							</>
						)}
					</div>
				</div>
			</nav>
		</header>
	);
}

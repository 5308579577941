import * as React from 'react';
import { Suspense } from 'react';

import { SidebarList } from '@/features/portal-layout/components/sidebar-list';

export function SidebarMenu() {
	return (
		<div className='flex h-full flex-col'>
			<Suspense
				fallback={
					<div className='flex flex-1 flex-col space-y-4 overflow-auto px-4'>
						{Array.from({ length: 10 }).map((_, i) => (
							<div key={i} className='h-6 w-full shrink-0 animate-pulse rounded-md bg-zinc-200 dark:bg-zinc-800' />
						))}
					</div>
				}>
				<SidebarList />
			</Suspense>
		</div>
	);
}

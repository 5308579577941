'use client';

import { Crisp } from 'crisp-sdk-web';
import * as React from 'react';

import { MessageCircleQuestion } from '@ui/components/icons/icons';
import { Button } from '@ui/components/ui/button';
import { Tooltip, TooltipContent, TooltipTrigger } from '@ui/components/ui/tooltip';

export default function ButtonSupport() {
	const handleClick = () => {
		Crisp.chat.show();
		Crisp.chat.open();
	};

	return (
		<Tooltip>
			<TooltipTrigger asChild>
				<Button onClick={handleClick} className='w-fit' size='icon' variant='ghost' type='button'>
					<MessageCircleQuestion className='size-5' />
					<span className='sr-only'>Chat met support</span>
				</Button>
			</TooltipTrigger>
			<TooltipContent>Chat met Support</TooltipContent>
		</Tooltip>
	);
}

'use client';

import { ReactNode } from 'react';

import { Menu } from '@ui/components/icons/icons';
import { Button } from '@ui/components/ui/button';
import { Sheet, SheetContent, SheetTrigger } from '@ui/components/ui/sheet';

import { Sidebar } from '@/features/portal-layout/components/sidebar';

interface SidebarMobileProps {
	children: ReactNode;
}

export function SidebarMobile({ children }: SidebarMobileProps) {
	return (
		<Sheet>
			<SheetTrigger asChild>
				<Button variant='ghost' className='-ml-2 flex size-9 p-0 lg:hidden'>
					<Menu className='size-6' />
					<span className='sr-only'>Toggle Sidebar</span>
				</Button>
			</SheetTrigger>
			<SheetContent side='left' className='inset-y-0 flex h-auto w-[300px] flex-col p-0'>
				<Sidebar className='flex'>{children}</Sidebar>
			</SheetContent>
		</Sheet>
	);
}
